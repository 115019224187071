:root{
  --marino:#010130;
  --mora:#99c2ff;
  --rosa:#ffccd4;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--rosa);
}

.headTitle {
  max-width: fit-content;
  min-height:100px;
}

.cartCounterBar {
  background-color: var(--mora);
  color:var(--marino);
}